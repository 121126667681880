import qs from 'query-string';
import pick from 'lodash/pick';
import { getStorageItem } from './utils/browser';

const jars = [
  pick(process.env, [
    'REACT_APP_API_URI',
    'REACT_APP_BASENAME',
  ]),
];

const overrideJar = {};

const env = new Proxy({}, {
  get(obj, key) {
    const query = qs.parse(window.location.search);
    if (query[key]) return query[key];
    try {
      if (getStorageItem(key)) return getStorageItem(key);
    } catch (err) {
      console.error(err);
    }
    if (overrideJar[key] !== undefined) return overrideJar[key];
    return jars.reduce((ret, jar) => jar[key] !== undefined ? jar[key] : ret, undefined);
  },
  set(obj, key, value) {
    overrideJar[key] = value;
    return true;
  }
});

export async function addJar(jar) {
  try {
    if (jar) jars.push(jar);
  } finally {
    return env;
  }
}

export default env;