import React, { useEffect, useMemo } from 'react';
import { useStoreContext } from '../context/store';
import QuestionScoreNumeric from './QuestionScoreNumeric';
import QuestionScoreSmiles from './QuestionScoreSmiles';
import QuestionScoreRadio from './QuestionScoreRadio';
import QuestionScoreBoolean from './QuestionScoreBoolean';
import QuestionScoreCSAT from './QuestionScoreCSAT';
import QuestionScoreCES from './QuestionScoreCES';
import get from 'lodash/get';
import { useI18n } from '../utils/i18n';
import Motives from './Motives';
import { Media } from './Media';
import { StoreSelector } from './StoreSelector';
import Footer from './Footer';
import Attachment from './Attachment';
import Presentation from './FormParts/Presentation';
import UserDataForm from './UserDataForm';
import { replaceVariables } from '../utils/replaceVariables';

const QuestionView = ({ module, answer = {}, onChange }) => {
  const { context, dispatch, instance } = useStoreContext();
  const { userContactData } = context;
  const { showErrors, contact, store, campaign } = context.data;
  const { settings, strings } = module.metadata;
  const { currentView } = context.ui;

  const { splitModuleComponents } = instance.settings.nav;

  useEffect(() => {
    if (module.type !== 'form') return;
    onChange({
      ...answer,
      userContactData,
    });
  }, [userContactData]);

  const media = get(settings, 'media');

  const t = useI18n();

  const allErrors = get(answer, 'errors');

  const errors = {
    score: allErrors?.filter((error) => error.target === 'score'),
    comment: allErrors?.filter((error) => error.target === 'comment'),
    motive: allErrors?.filter((error) => error.target === 'motive'),
  };

  const voteToShowMotives = get(
    module,
    'metadata.settings.voteToShowMotives',
    false
  );

  const motiveCondition =
    (get(answer, 'score') &&
      get(answer, 'dirty') &&
      get(module, 'score_type', null) !== null) ||
    get(module, 'score_type', null) === null;

  const showMotives =
    (voteToShowMotives && motiveCondition) || !voteToShowMotives;

  const iframeQuestionCondition =
    (splitModuleComponents && currentView === 'question') ||
    !splitModuleComponents;
  const iframeMotiveCondition =
    (splitModuleComponents && currentView === 'motives') ||
    !splitModuleComponents;
  const showNameCondition =
    (splitModuleComponents &&
      ((module.score_type == null && currentView === 'motives') ||
        (module.score_type != null && currentView === 'question'))) ||
    !splitModuleComponents;

  function setScore(score) {
    if (answer.disabled) return;
    answer.setScore(score);
  }

  const { style: questionStyle } = module.getStyle();

  const showAsterisks = get(
    context,
    'data.poll.metadata.settings.showRequiredQuestions',
    false
  );

  const { style: descriptionStyle } = module.getDescriptionStyle();

  useEffect(() => {
    if (answer.visited) return;
    if (module.type === 'form') {
      dispatch({
        type: 'userContactData.update',
        payload: get(module, 'metadata.form.fields', []),
      });
    }
  }, [dispatch, module]);

  const questionDescription = useMemo(() => {
    return replaceVariables(module.getDescription(), store, contact, campaign)
  }, [module, contact, store, campaign]);

  const questionName = useMemo(() => {
    return replaceVariables(get(module, 'name', get(module, 'nombre')), store, contact, campaign)
  }, [module, contact, store, campaign]);

  const descriptionMarginBottom = get(settings,'descriptionMargin',0);

  const questionRender = (
    <>
      {questionDescription && get(settings, 'descriptionPosition', 'above') === 'above' && (
        <div style={{...descriptionStyle, marginBottom:`${descriptionMarginBottom}px`}} className={'question-description'}>
          { questionDescription }
        </div>
      )}
      {showNameCondition && get(module, 'type') !== 'form' && (
        <h3 style={questionStyle} className={'pregunta'}>
          <div data-testid={`name${module.id}`}>
            {questionName === 'write-question' ? t(questionName) : questionName}
            {module.isRequired && showAsterisks ? (
              <span style={{ color: 'red' }}>*</span>
            ) : null}
          </div>
        </h3>
      )}
      {questionDescription && get(settings, 'descriptionPosition', 'above') === 'below' && (
        <div style={{ ...descriptionStyle, marginBottom: '20px', marginTop: '0px' }} className={'question-description'}>
          { questionDescription }
        </div>
      )}
      {module.score_type === 'smiles' && iframeQuestionCondition && (
        <QuestionScoreSmiles
          question={module}
          answer={answer}
          settings={settings}
        />
      )}

      {module.score_type === 'radio' && iframeQuestionCondition && (
        <QuestionScoreRadio
          question={module}
          value={answer}
          settings={settings}
          onChange={onChange}
        />
      )}

      {module.score_type === 'boolean' && iframeQuestionCondition && (
        <QuestionScoreBoolean
          question={module}
          setScore={setScore}
          value={answer}
          settings={settings}
        />
      )}

      {module.score_type === 'numeric' && iframeQuestionCondition && (
        <QuestionScoreNumeric
          question={module}
          setScore={setScore}
          answer={answer}
        />
      )}

      {module.score_type === 'csat' && iframeQuestionCondition && (
        <QuestionScoreCSAT
          question={module}
          answer={answer}
          setScore={setScore}
          settings={settings}
        />
      )}

      {module.score_type === 'ces' && iframeQuestionCondition && (
        <QuestionScoreCES
          question={module}
          answer={answer}
          setScore={setScore}
        />
      )}

      {module.type === 'store' && (
        <StoreSelector onChange={onChange} value={answer} question={module} />
      )}

      {module.type === 'attachment' && (
        <Attachment answer={answer} onChange={onChange} t={t} />
      )}

      {module.type === 'information' && (
        <Presentation informationModule question={module} />
      )}

      {module.type === 'form' && (
        <div className="container">
          <div className="inside">
            <UserDataForm campaign={module} />
          </div>
        </div>
      )}

      {showErrors && get(errors, 'score', []).length ? (
        <p data-testid="error" className={'error'}>
          * {t('errors.complete-question')}
        </p>
      ) : null}

      {module.type === 'question' && showMotives && iframeMotiveCondition ? (
        <Motives
          question={module}
          settings={settings}
          strings={strings}
          value={answer}
          onChange={onChange}
          showErrors={context.data.showErrors}
          errors={errors}
        />
      ) : null}

      {get(module, 'metadata.settings.score.footer.text', false) && (
        <Footer question={module} />
      )}
    </>
  );

  return (
    <div
      data-testid={`question${module.id}`}
      className={`score_${module.score_type}`}
    >
      {media && (
        <Media
          url={media.url}
          settings={get(media, 'settings', {})}
          type={media.type}
          onEnd={() => answer?.setMediaProgress(100)}
        />
      )}

      {!answer?.disabled && questionRender}
    </div>
  );
};

export default QuestionView;
