import React from "react";

const DefaultLayout = ({ children }) => (
  <div className='flex-container container-fluid'>
    <div className='row' style={{ alignItems: 'center' }}>
      <div className='col'
      >
        {children}
      </div>
    </div>
  </div>
);
export default DefaultLayout;
