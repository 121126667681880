import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import carita_excelente from '../assets/excelente.png';
import carita_mal from '../assets/mal.png';
import { useI18n } from '../utils/i18n';
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
  AiOutlineCheckCircle,
  AiFillCheckCircle,
  AiOutlineCloseCircle,
  AiFillCloseCircle,
} from 'react-icons/ai';

const smiles = [
  {
    score: 10,
    alt: 'excellent',
    src: carita_excelente
  },
  {
    score: 1,
    alt: 'bad',
    src: carita_mal
  },
  {
    score: null,
    alt: 'NA',
    src: 'image_na'
  }
];

const QuestionScoreBoolean = ({ question, value, settings, setScore }) => {

  let icons = get(settings, 'ui.icons');

  const t = useI18n();
  const yes =  get(icons, 'yes');
  const no =  get(icons, 'no');
  const na = get(icons, 'na');

  const getCustomIcons = (icon, size) => {

    const customIcons = {
      LikeOutlined: <AiOutlineLike style={{ fontSize: size }} />,
      LikeFilled: <AiFillLike style={{ fontSize: size }} />,
      DislikeOutlined: <AiOutlineDislike style={{ fontSize: size }} />,
      DislikeFilled: <AiFillDislike style={{ fontSize: size }} />,
      CheckCircleFilled: <AiFillCheckCircle style={{ fontSize: size }} />,
      UncheckCircleFilled: <AiOutlineCloseCircle style={{ fontSize: size }} />,
      CheckCircleOutlined: <AiOutlineCheckCircle style={{ fontSize: size }} />,
      UncheckCircleOutlined: <AiFillCloseCircle style={{ fontSize: size }} />,
    }

    return customIcons[icon];
  }


  const filtered = smiles.filter((smile) => smile.score !== null || settings.allowScoreNA);

  return (
    <div>
      {!icons ? (
        <ul height="100px" className={`caritaStyle`}>
          <div className={'tabla'}>
            <button
              data-testid={question.id+''+10}
              onClick={() => setScore(10)}
              key={1}
              className={classNames('motivo', {
                'selected': get(value,'score') === 10,
              })}
            >
              {t('yes')}
            </button>
            <button
              data-testid={question.id+''+1}
              style={{ marginLeft: '20px' }}
              onClick={() => setScore(1)}
              key={2}
              className={classNames('motivo', {
                'selected': get(value,'score') === 1,
              })}
            >
              {t('no')}
            </button>
            {settings.allowScoreNA ? (
              <button
                data-testid={question.id+'null'}
                style={{ marginLeft: '20px' }}
                onClick={() => setScore(null)}
                key={3}
                className={classNames('motivo', {
                  'selected': get(value,'score') === null,
                })}
              >
                N/A
              </button>
            ) : null}
          </div>
        </ul>
      ) : (yes.name === "excellent" && no.name === "bad" && na.name === "na") ?

          (<ul height="100px" className={`caritaStyle count-${filtered.length}`}>
            {filtered.map((smile) => {
              return (
                <li key={smile.src}>
                  <img
                    data-testid={question.id+''+smile.score}
                    src={t(smile.src)}
                    alt={t(smile.alt)}
                    className={
                      value && get(value,'score') === undefined
                        ? 'estadoInicial'
                        : value &&
                          get(value, 'module.id') === question.id &&
                          get(value,'score') === smile.score
                          ? 'select'
                          : 'sinselect-gray'
                    }
                    onClick={() => setScore(smile.score)}
                  />
                </li>
              );
            })}
          </ul>) :

          (<ul key='customIcons' className={`caritaStyle count-${filtered.length}`}>

            {Object.values(icons).map((icon, index) => {

              const name = get(icon, 'name');
              const size = get(icon, 'size');
              const type = Object.keys(icons)[index];
              const score = type === "yes"? 10:type === "no"?1:null;
              return (
                <li key={index} className={
                  icon && score === undefined
                    ? 'estadoInicial'
                    : icon &&
                      value.pregunta === question.id &&
                      get(value,'score') === score
                      ? 'select'
                      : 'sinselect'

                }
                  data-testid={question.id+''+score}
                  onClick={() => setScore(score)}
                >
                  {getCustomIcons(name, size)}
                </li>
              );
            })}
          </ul>

          )
      }

    </div>
  );
};

export default QuestionScoreBoolean;
