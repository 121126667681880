import React, { useState } from 'react';
import get from 'lodash/get';
import SmileRate from '../../utils/SmileRate';
import { DEGRADED_COLORS } from '../../utils/constants';
import { Legends } from '../ui/Legends';
import ButtonLayout from './ButtonLayout';
import RadioLayout from './RadioLayout';

const getArray = (min, max) => {
  let numeric = [];

  for (let i = 0; i <= max - min; i++) {
    numeric[i] = {score: i + min};
  }

  return numeric;
};

const QuestionScoreNumeric = ({ question, answer, setScore }) => {
  const min = get(question, 'metadata.settings.score.min', 0);
  const max = get(question, 'metadata.settings.score.max', 10);

  const [numericValue, setNumericValue] = useState(
    get(answer,'score')
    ? ((get(answer,'score') - 1) * (max - min)) / 9 + min
    : (get(answer,'score') === null ? 'N/A' : undefined)
  );

  const numeric = get(question, 'metadata.settings.allowScoreNA')
    ? [...getArray(min, max), {score: 'N/A'}]
    : getArray(min, max);

  const setNumericScore = (voteValue, min, max) => {
    if(answer.disabled) return;
    if (voteValue === 'N/A') {
      setScore(null);
      setNumericValue(voteValue);
    } else {
      setNumericValue(voteValue);
      const score = ((voteValue - min) * 9) / (max - min) + 1;
      setScore(score);
    }
  };

  const layout = get(question, 'metadata.settings.score.layout');

  const customIcon = get(
    question,
    'metadata.settings.score.icon.name',
    'StarFilled'
  );
  const customIconColor = get(question, 'metadata.settings.score.icon.color');
  const customIconSize = get(question, 'metadata.settings.score.icon.fontSize');
  const labelsLayout = get(
    question,
    'metadata.settings.score.labelsLayout',
    'down'
  );

  const completNumeric = numeric.map((values, index) => {
    return {
      score: values === 'N/A' ? null : values.score
    }
  });

  const { worst, best, sizeLabels = 14 } = get(question, 'metadata.settings.score', []);


  return (
    <div className='numeric-layout-container'>
      {labelsLayout === 'up' && <Legends best={best} worst={worst} size={sizeLabels}/>}
      {layout === 'custom' && (
        <SmileRate
          max={max}
          icon={customIcon}
          style={{ color: customIconColor, fontSize: customIconSize }}
          onChange={(value) => setNumericScore(value, min, max)}
          value={numericValue}
        />
      )}

      {layout === 'button' && <ButtonLayout
        question={question}
        completNumeric={completNumeric}
        numericValue={numericValue}
        min={min}
        max={max}
        setNumericScore={setNumericScore}
      /> }

      {layout === 'numeric' && <RadioLayout
        question={question}
        completNumeric={completNumeric}
        numericValue={numericValue}
        min={min}
        max={max}
        answer={answer}
        setNumericScore={setNumericScore}
      /> }

      {labelsLayout === 'down' && <Legends best={best} worst={worst} size={sizeLabels}/>}
    </div>
  );
};

export default QuestionScoreNumeric;
