import React, { useState, useEffect } from 'react';
import { useInstanceEvent, useStoreContext } from '../../context/store';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import Button from '../ui/Button';
import get from 'lodash/get';
import {QuestionsMatrix, Module as Question } from '@smile-labs/feedback-sdk';
import { useTheme } from '../../hooks/sdk';
import isFunction from 'lodash/isFunction';
import { validateForm } from '../../utils/core';
import { restoreFormVisited } from '../../utils/restoreFormVisited';

const Arrows = ({ steps, onStepChange, renderSendButton }) => {

  const { context, dispatch, instance } = useStoreContext();
  const answers = useInstanceEvent('nav', (instance) => instance.nav.answers);
  const { splitModuleComponents } = instance.settings.nav;

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { userData } = context;
  const { showErrors } = context.data;

  const modules = useInstanceEvent('modules:change', instance => instance.modules)

  const theme = useTheme();

  const {
    startIndex,
    prevStepIndex,
    nextStepEnabled,
    nextStepIndex,
    atLastPage,
    currentView,
    working,
    autoStepForm,
  } = context.ui;
  const currentStep = steps ? steps[currentStepIndex] : undefined;
  const prevQuestion = modules[prevStepIndex];
  const nextQuestion = modules[nextStepIndex];

  const currentQuestion = modules.find((question, index) => index === startIndex);

  useEffect(() => {
    window.scrollTo(0, 0);
    onStepChange(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if(autoStepForm && currentStepIndex === 0) {
      setCurrentStepIndex((prev) => prev + 1);
      dispatch({
        type: 'ui.update',
        payload: {
          autoStepForm: false,
        },
      });
    }
  }, [currentStepIndex, autoStepForm])

  if (!currentStep) return null;


  const prevStepShown = (() => {
    if (currentStep.type === 'questions' && prevStepIndex >= 0) return true;
    if (splitModuleComponents) {
      if (currentStepIndex - 1 === -1) {
        if (currentView === 'motives' && get(currentQuestion,'score_type') != null) return true;
      } else {
        return steps[currentStepIndex - 1];
      }
    } else {
      return steps[currentStepIndex - 1];
    }
  })();

  const conditionLastView = ((currentQuestion instanceof Question  &&
    (
      (currentView === 'question' && !currentQuestion.hasMotives()) ||
      (currentView === 'motives' && (currentQuestion.hasMotives() || get(currentQuestion,'score_type') === null))
    )
  ) || currentQuestion instanceof QuestionsMatrix);
  const questionsStep = steps.find((step) => step.type === 'questions');
  const atLastStep = currentStepIndex === steps.length - 1;
  const atEnd = ((!splitModuleComponents && atLastStep && (questionsStep ? atLastPage : true)) ||
    (splitModuleComponents && atLastStep && (questionsStep ? atLastPage : true) &&
      conditionLastView
    ));


  const nextStepShown = (() => {
    if (currentStep.type === 'questions' && !atLastPage) return true;
    if (splitModuleComponents && atLastPage && currentStep.type === 'questions' && (currentView === 'question' && currentQuestion instanceof Question && currentQuestion.hasMotives())) return true;
    return steps[currentStepIndex + 1];
  })();

  const nextStepDisabled = (() => {
    if (currentStep.type === 'questions') {
      if(get(currentQuestion,'type') === 'form') {
        if(get(userData, 'emailValidation', true) === false || get(userData, 'birthdayValidation') === false) return true;
        return !validateForm(context);
      }

      const answer = answers.find(answer => answer.module?.id === currentQuestion?.id);
      if (get(answer, 'score')
        && currentQuestion instanceof Question && currentQuestion.hasMotives()
        && currentView === 'question' && splitModuleComponents
      ) {
        return false;
      }
      return !nextStepEnabled;
    }

    if(get(userData, 'emailValidation', true) === false || get(userData, 'birthdayValidation') === false) {
      return true;
    } else {
      if (!currentStep) return;
      return isFunction(currentStep.done) ? !currentStep.done(context) : !(currentStep.done ?? true);
    }

  })();

  const gotoPrevStep = () => {
    restoreFormVisited(instance, answers, currentQuestion);
    if(currentStep.type === 'questions' && !prevQuestion && !splitModuleComponents) return setCurrentStepIndex(currentStepIndex - 1);
    if (currentStep.type === 'questions' && (prevStepIndex >= 0 || currentView === 'motives')) {
      window.scrollTo(0, 0);
      if (currentView === 'motives' && get(currentQuestion,'score_type') != null) {
        return dispatch({
          type: 'ui.update',
          payload: {
            currentView: 'question',
            startIndex: splitModuleComponents ? startIndex : prevStepIndex,
          },
        });
      } else {
        if(!prevQuestion) return setCurrentStepIndex(currentStepIndex - 1);
        let view = 'question';
        const answer = answers.find(answer => answer.module.id === prevQuestion.id);
        if (prevQuestion instanceof Question && (get(prevQuestion,'score_type') === null || (prevQuestion.hasMotives() && answer.score))) view = 'motives';
        return dispatch({
          type: 'ui.update',
          payload: {
            currentView: view,
            startIndex: prevStepIndex,
          },
        });
      }

    }

    return setCurrentStepIndex(currentStepIndex - 1);
  }

  const handleNextStep = () => {
    if(get(userData, 'emailValidation', true) === false) {
      dispatch({
        type: 'data.update',
        payload: {
          showErrors: true,
        },
      });
    }
  }

  const gotoNextStep = () => {
    if(nextStepDisabled || working){
      dispatch({
        type: 'data.update',
        payload: {
          showErrors: true,
        },
      });
      return;
    };
    if(showErrors) {
      dispatch({
        type: 'data.update',
        payload: {
          showErrors: false,
        },
      });
    }
    if (currentStep.type === 'questions' && (!atLastPage || !conditionLastView)) {
      window.scrollTo(0, 0);
      const answer = answers.find(answer=>answer.module?.id===currentQuestion?.id);
      if (currentView === 'question' && currentQuestion instanceof Question && currentQuestion.hasMotives() && get(answer, 'score')) {
        return dispatch({
          type: 'ui.update',
          payload: {
            currentView: 'motives',
            startIndex: splitModuleComponents ? startIndex : nextStepIndex,
          },
        });

      } else {
        let view = currentView;
        if (!get(nextQuestion,'score_type')) {
          view = 'motives';
        } else {
          view = 'question';
        }

        return dispatch({
          type: 'ui.update',
          payload: {
            currentView: view,
            startIndex: nextStepIndex,
          },
        });
      }
    }
    return setCurrentStepIndex(currentStepIndex + 1);
  }

  return (
    <div className={'bottom-bar'}>

      {theme.buttonType === 'default' ? (
        <div className="inside-bottom-bar">
          <div style={{ alignSelf: 'center' }}>
            {prevStepShown && (
              <Button
                data-testid="prev-button"
                pill
                theme="secondary"
                className="shadowed"
                onClick={gotoPrevStep}
                size="sm">
                <AiOutlineArrowLeft />
              </Button>
            )}
          </div>
          {atEnd ? (
            <div
              style={prevStepShown ? { marginLeft: "-36px" } : undefined}>
              {renderSendButton()}
            </div>) : null}
          <div style={{ alignSelf: 'center' }} onClick={()=>{
            if(nextStepDisabled) handleNextStep()
          }}>
            {nextStepShown && (
              <Button
                style={nextStepDisabled || working  ? {backgroundColor:'#cacaca', opacity:'0.6'} : {} }
                data-testid="next-button"
                pill
                theme="secondary"
                className="shadowed"
                onClick={gotoNextStep}
                size="sm">
                <AiOutlineArrowRight size={18} data-testid='arrow-next' />
              </Button>
            )}
          </div>
        </div>)
        : (
          <div className="inside-bottom-bar">
            <div style={{ alignSelf: 'center' }}>
              {prevStepShown && (
                <Button
                  pill
                  theme="secondary"
                  className="shadowed"
                  onClick={gotoPrevStep}
                  size="sm"
                >
                  <AiOutlineArrowLeft />
                </Button>
              )}
            </div>

            <div style={{ alignSelf: 'center' }}>
              {nextStepShown && (
                <Button
                  pill
                  theme="secondary"
                  className="shadowed"
                  disabled={nextStepDisabled}
                  onClick={gotoNextStep}
                  size="sm">
                  <AiOutlineArrowRight />
                </Button>
              )}
            </div>
            {atEnd ? (
              <div
                style={prevStepShown ? { marginLeft: "-36px" } : undefined}>
                {renderSendButton()}
              </div>) : null}
          </div>
        )}

    </div>
  )
};

export default Arrows;
