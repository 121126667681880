import { getStorageItem } from "./browser";

export const enabled = () => {
  try {
    const debug = getStorageItem('debug');
    return ['true', true].includes(debug);
  } catch (e) {
    return false;
  }
}
export const log = (...args) => {
  if (!enabled()) return;
  //console.log(...args);
}

export const error = (...args) => {
  if (!enabled()) return;
  //console.error(...args);
}
