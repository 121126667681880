export const COLORS = {
    promotor:'#4bc3b4',
    detractor:'#c32420',
    neutral:'#eac462',
    notApply: 'gray'
};

export const DEGRADED_COLORS = [
    {color: '#b72124', score: 1},
    {color: '#d62127', score: 1.9},
    {color: '#ef5222', score: 2.8},
    {color: '#f56f21', score: 3.7},
    {color: '#faa822', score: 4.6},
    {color: '#ffca27', score: 5.5},
    {color: '#ecdb10', score: 6.4},
    {color: '#dedd37', score: 7.3},
    {color: '#c5d92e', score: 8.2},
    {color: '#afd135', score: 9.1},
    {color: '#64b54c', score: 10},
];