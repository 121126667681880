import { lazy } from "react";

import DefaultLayout from "./layouts/DefaultLayout";

import Thanks from './views/Thanks';
import Form from './views/Form';
const MockedForm = lazy(() => import('./views/MockedForm'));
const TokenForm = lazy(() => import('./views/TokenForm'));

const routes = [
  {
    path: "/form/:hash?",
    exact: true,
    layout: DefaultLayout,
    public: true,
    component: Form
  },
  {
    path: "/thanks",
    layout: DefaultLayout,
    public: true,
    component: Thanks
  },
  {
    path: "/form/:hash?/thanks",
    layout: DefaultLayout,
    public: true,
    component: Thanks
  },
  {
    path: "/:hash?",
    exact: true,
    layout: DefaultLayout,
    public: true,
    component: Form
  },
  {
    path: "/:hash?/thanks",
    layout: DefaultLayout,
    public: true,
    component: Thanks
  },
];

if (window.location.hostname === 'localhost') routes.push({
  path: "/mock/:key?",
  exact: true,
  layout: DefaultLayout,
  public: true,
  component: MockedForm,
});

if (window.location.hostname === 'localhost') routes.push({
  path: "/token/:token?",
  exact: true,
  layout: DefaultLayout,
  public: true,
  component: TokenForm,
});

export default routes;
