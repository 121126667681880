
import get from 'lodash/get';
import { useState, useEffect } from 'react';
import { useInstanceEvent, useStoreContext } from '../context/store';
import { validateForm } from '../utils/core';

const useBuildSteps = ({ hasQuestionForm }) => {
  const { context } = useStoreContext();

  const { campaign } = context.data;

  const poll = useInstanceEvent('poll', instance => instance.poll);

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    // Build Steps
    const steps = [];

    if (get(poll, 'settings.presentation', false)) {
      steps.push({ type: 'presentation' });
    }

    const formStep = !hasQuestionForm &&
      get(campaign, 'metadata.form.enabled', true) &&
      get(campaign, 'metadata.form.fields', []).length
        ? {
            type: 'form',
            done: function(context){
              return validateForm(context)
            }
          }
        : undefined;

    if (
      formStep &&
      get(campaign, 'metadata.form.location', 'start') === 'start'
    ) {
      steps.push(formStep);
    }

    const sortedQuestions = poll?.sortedQuestions ?? [];

    //pusheo una pregunta vacia para que quede en el que sigue el comment fields

    // if (false && isIframe && (poll.settings.commentsFields || poll.settings.userDataFields)) {
    //   sortedQuestions.push([]);
    // }

    if (sortedQuestions.length > 0) {
      steps.push({
        type: 'questions',
        questions: sortedQuestions,
        isLastStep: true,
        done: ({ answers }) => {
          // if (isLastStep) return false;
          return sortedQuestions.every((entry) => entry.isValid({answers}));
        },
      });
    }

    if (
      formStep &&
      get(campaign, 'metadata.form.location', 'start') === 'end'
    ) {
      steps.push(formStep);
    }
    setSteps(steps);
  }, [poll, campaign, hasQuestionForm]);

  return steps;
}

export default useBuildSteps;
