import { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {CompanyEvent, getOneOf, parseFormData} from '@smile-labs/feedback-sdk';
// import * as Sentry from "@sentry/browser";

import { log } from '../utils/debug';
import { auth } from '../utils/Smilehelper';
import get from 'lodash/get';
import { useStoreContext } from '../context/store';
import { bridge } from '../utils/bridge';
import set from 'lodash/set';
import { getStorageItem } from '../utils/browser';
import { useTranslation } from 'react-i18next';

const EXCLUDED_APP_MODES = ['playground', 'iframe'];

export const useFormData = (mockData) => {
  const [data, setData] = useState(mockData ? parseFormData(mockData) : undefined);
  const authorization = getStorageItem('Authorization', undefined);
  const history = useHistory();

  const { i18n } = useTranslation();

  const { context, dispatch, instance } = useStoreContext();
  const [frameOrigin, setFrameOrigin] = useState();
  const match1 = useRouteMatch('/:hash');
  const match2 = useRouteMatch('/form/:hash');
  const match = match2 || match1;

  const hash = get(match, 'params.hash');

  const appMode = get(context, 'state.appMode');

  useEffect(() => {
    dispatch({
      type: 'update',
      path: 'state',
      payload: {
        hash,
        appMode: ['iframe', 'playground'].includes(hash) ? hash : 'web',
      },
    });
  }, [dispatch, hash]);

  useEffect(() => {
    try {
      bridge.on('view-port', ({ size }) => {
        dispatch({
          type: 'ui.update',
          payload: {
            scrollContainerStyle: {
              maxHeight: `${size.height}px`,
            },
          },
        });
      });
      bridge.on('headers', (headers) => {
        auth.setHeaders(headers);
        // Sentry.setTag('bridge_headers', headers);
      });
      bridge.on('origin', (origin) => {
        setFrameOrigin(origin);
        // Sentry.setTag('bridge_origin', origin);
      });
      bridge.on('external-id', (externalId) => {
        instance.sdk.externalId = externalId;
        // Sentry.setTag('externalId', externalId);
      });

      bridge.on('url_from', (url_from) => {
        dispatch({
          type: 'update',
          path: 'data.url_from',
          payload: {
            url_from,
          },
        });
        instance.sdk.setHeader('X-Referer', url_from);
        // Sentry.setTag('url_from', url_from);
      });


      bridge.on('setData', async (data) => {
        dispatch({
          type: 'session.update',
          payload: {
            requestDone: true,
          },
        });
        await instance.sdk.parseRawFormData(data);
      });
      bridge.on('language', (language) => {
        i18n.changeLanguage(language);
      })
      bridge.on('user-context', (payload) => {
        instance.sdk.appendUserContext(payload);
        log('on:user-context', payload);
      });
      bridge.dispatchEvent('smile/feedback/ready', {});
    } catch (err) {}
    // return bridge.off();
  }, [instance, dispatch, i18n]);

  useEffect(() => {
    instance.sdk.on('form-data', ({ payload: parsed, raw: response }) => {
      if (!parsed) return;
      const poll = parsed.poll || parsed.polls[0];
      instance.setPoll(poll);

      if (response) {
        dispatch({
          type: 'session.update',
          payload: {
            requestDone: true,
          },
        });
      }
      if (parsed.values.appMode) {
        dispatch({
          type: 'update',
          payload: {
            appMode: parsed.values.appMode,
          },
        });
      }
      if (parsed.values.expired) {
        dispatch({
          type: 'data.update',
          payload: {
            ...response,
            expired: parsed.values.expired,
            hash,
          },
        });
        history.push(`/${hash}/thanks`);
      }
      
      setData({
        ...response,
        poll: parsed.polls[0],
        polls: parsed.polls,
        hash,
      });
    });
  }, [dispatch, hash, instance]);

  useEffect(() => {
    if (mockData) {
      instance.sdk.parseRawFormData(mockData);
    }
  }, [instance, mockData]);

  useEffect(() => {
    if (mockData || (!hash && !authorization) || !appMode) return;
    if (EXCLUDED_APP_MODES.includes(appMode)) return;
    if (get(context, 'session.requestDone')) return;
    (async () => {
      try {
        await instance.sdk.authorize({
          hash,
          token: authorization,
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [instance, appMode, authorization, context, hash, mockData]);

  useEffect(() => {
    if (appMode === 'tablet') {
      document.addEventListener(
        'click',
        function () {
          if (window.location.pathname === `/form/${hash}`) {
            setTimeout(() => {
              if (hash) {
                window.location.replace(
                  `https://form.smileweb.net/form/${hash}`
                );
              }
            }, get(data, 'campaign.metadata.settings.inactivity_time', 15000));
          }
        },
        false
      );
    }
    if (['iframe', 'tablet'].includes(appMode)) {
      instance.setSettingsOverride({
        nav: {
          pageSize: 1,
          splitModuleComponents: true,
          pageBreaks: [],
        }
      });
    }
  }, [instance, appMode, data, hash]);

  useEffect(() => {
    if (frameOrigin) {
      bridge.setDefaultTarget({
        contentWindow: window.parent,
        origin: frameOrigin,
      });
      bridge.emit('ready', {});
    }
  }, [frameOrigin]);

  useEffect(() => {
    if (!data) return;
    if (data.polls) {
      data.poll = data.polls[Math.floor(Math.random() * get(data, 'polls', []).length)];
    }

    const thanks = getOneOf([data.campaign, data.device, data.encuesta], 'metadata.thanks');

    set({...thanks}, 'redes', getOneOf([data.campaign, data.device, data.encuesta], 'metadata.redes'));

    dispatch({
      type: 'data.update',
      payload: {
        device: {},
        ...data,
        poll: data.poll,
        links: getOneOf([data.campaign, data.device, data.poll], 'metadata.links', []),
        thanks: thanks,
        encuesta: undefined,
        contact: get(data, 'contact', false),
        store: get(data, 'store', false),
        events: (data.events || []).map((event) => new CompanyEvent(event)),
      },
    });

    if (get(data, 'campaign.answered')) {
      history.push(`/${hash}/thanks`);
    }

    if (!data?.campaign) return;
    dispatch({
      type: 'userContactData.update',
      payload: get(data, 'campaign.metadata.form.fields', []),
    });


    // GOOGLE ANALYTICS
    // // INICIALIZAR REACT GA
    // ReactGA.initialize({
    //   trackingId: get(data, 'metrics.trackingId', 'UA-145188741-2'),
    //   gaOptions: {
    //     name: 'smileTracker'
    //   }
    // },
    //   {
    //     // debug: true,
    //     // alwaysSendToDefaultTracker: false
    //   }
    // );

    // // console.log(data);
    // const labelHashCampaign = get(data, 'hash','');
    // const labelPoll = get(data,'poll.id','');
    // const label = 'form:'+`${labelHashCampaign}`+', poll:'+`${labelPoll}`;

    // const events = getGAevents(label);
    // // console.log(events.Load);

    // ReactGA.pageview(window.location.href, ['smileTracker']);

    // try {
    //   ReactGA.event(events.Load, ['smileTracker']);
    // } catch (e) {
    //   console.log(e);
    // }
  }, [data]);

  return data;
};
