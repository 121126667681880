import poweredBySmile from '../../assets/powered-by-smile.png';
import poweredBySmileWhite from '../../assets/powered-by-smile-blanco.png';
import { useStoreContext } from '../../context/store';
import get from 'lodash/get';

const PoweredBySmile = () => {
  const { context } = useStoreContext();
  
  const isDark = context.ui.isDark;

  const poweredBySmileSrc = isDark ? poweredBySmileWhite : poweredBySmile;

  return (
    <div style={{ marginTop: '5px', width: '100%' }}>
      <a
        data-testid="poweredBySmile"
        href={`https://smileweb.net/es?utm_source=smle&utm_medium=pb&utm_campaign=${get(
          context,
          'state.hash'
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        <img className="poweredSmile" src={poweredBySmileSrc} />
      </a>
    </div>
  );
}

export default PoweredBySmile;
