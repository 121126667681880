export const getStorageItem = (key, defValue = null) => {
  try {
    const value = window.localStorage.getItem(key);
    if (value === null) return defValue;
    return value;
  } catch (err) {
    return defValue;
  }
}

export const setStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (err) {
  }
}

export const removeStorageItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
  }
}