import pick from "lodash/pick";

function invalidResponseCondition(entry) {
  return (!entry.response || (!entry.validated && entry.validation))
}

export function validateForm(context){
  const required = context.userContactData.filter(
    (filter) => filter.required === true
  );
  
  const invalidResponses = required.reduce(
    (ret, entry) =>
      ret + (invalidResponseCondition(entry) ? 1: 0),
    0
  );
  return invalidResponses === 0 ? true : false;
}

export function buildFormDataPayload(dataToSend){
  const {
    answers,
    deviceType,
    contactData,
    form,
    store_key,
    userContext,
    url_from,
    progress_percentage,
  } = dataToSend;

  const response = {
    form,
    user_context: userContext,
    url_from,
    store_key,
    progress_percentage,
  }

  if (deviceType !== undefined) Object.assign(response, {
    deviceType,
  })

  if (contactData !== undefined) Object.assign(response, {
    contactData,
  })

  if (answers) {
    const firstEntry = answers[0];
    if (firstEntry) {
      Object.assign(response, {
        ...pick(firstEntry, [
          'poll_id',
          'comment',
          'mail',
          'telefono',
          'device_id',
        ]),
        answers: [],
      });
    }
    response.answers = answers.map((entry) => {
      const find = response.answers.find((answer) => answer.id === entry.id);
      const answer = find || {
        ...pick(entry, [
          'id',
          'score',
          'comment',
        ]),
        motives: [],
        src: entry.src,
      };
      if (entry.motivo) answer.motives.push(entry.motivo);
      return answer;
    });
  }

  return response;
}