import React from 'react';
import get from 'lodash/get';
import Button from '../ui/Button';
import { useStoreContext } from '../../context/store';

export const Links = () => {
  const { context } = useStoreContext();

  const { campaign, links = [] } = context.data;

  const title = get(campaign, 'metadata.form.title');
  const titleSize = get(campaign, 'metadata.form.titleSize');
  const marginBottomTitle = get(
    campaign,
    'metadata.form.marginBottomTitle',
    '10px'
  );
  const marginTopTitle = get(campaign, 'metadata.form.marginTopTitle', '10px');
  const subtitle = get(campaign, 'metadata.form.subtitle');
  const subtitleSize = get(campaign, 'metadata.form.subtitleSize');
  const marginBottomSubtitle = get(
    campaign,
    'metadata.form.marginBottomSubtitle',
    '20px'
  );
  const footer = get(campaign, 'metadata.form.footer');
  const footerSize = get(campaign, 'metadata.form.footerSize');
  const marginTopFooter = get(campaign, 'metadata.form.marginTopFooter', '5px');
  const marginBottomFooter = get(
    campaign,
    'metadata.form.marginBottomFooter',
    '5px'
  );

  const image = get(campaign, 'metadata.form.image');

  const redes = get(campaign, 'metadata.redes', []);

  return (
    <div className="thanks-view">
      <div
        style={{
          fontSize: titleSize + 'px',
          marginTop: marginTopTitle + 'px',
          marginBottom: marginBottomTitle + 'px',
        }}
        className="title"
      >
        {title}
      </div>
      <div
        style={{
          fontSize: subtitleSize + 'px',
          marginBottom: marginBottomSubtitle + 'px',
        }}
        className="subtitle"
      >
        {subtitle}
      </div>
      <div style={{ marginTop: '10px' }}></div>
      {links.map((link, i) => (
        <div
          style={{ width: '-webkit-fill-available', marginTop: '10px' }}
          key={i}
        >
          <a
            href={link.href}
            target={link?.redirect ? '_blank' : ''}
            data-testid={`link-href-${i}`}
          >
            <Button
              className={'boton'}
              data-testid={`link-text-${i}`}
              style={{
                width: '-webkit-fill-available',
                marginRight: '20px',
                marginLeft: '20px',
                height: '50px',
                borderRadius: '10px',
                maxWidth: '-webkit-fill-available',
              }}
            >
              {link.text}
            </Button>
          </a>
        </div>
      ))}
      <div
        style={{
          width: '90%',
          margin: 'auto',
          fontSize: footerSize + 'px',
          marginTop: marginTopFooter + 'px',
          marginBottom: marginBottomFooter + 'px',
        }}
        className="footer"
      >
        {footer}
      </div>
      {image && (
          <div className={'pregunta'}>
            <img alt="link-image" className='imagen' src={image} />
          </div>
        )}
      <div className="footer">
        <div className={'multiplesRedes'}>
          {redes.map((carita) => (
            <div key={carita.id} style={{ margin: '15px' }}>
              <a href={carita.href}>
                {/* <img alt={carita.id} className={redes !== redesDefault? ('bigRedes'):('redes')} src={carita.src}></img> */}
                <i className={'icon ' + carita.class} />
                <span className={'spanIcon'}>{carita.span}</span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Links;
