//import decode from 'jwt-decode';
import axios from 'axios';
import env from '../env';

export default class AuthService {
  stickyHeaders = {};
  commonHeaders = {};

  get baseUrl() {
    return env.REACT_APP_API_URI;
  }

  setHeaders(value) {
    Object.assign(this.commonHeaders, value ?? {});
  }

  async get(url, params = false) {

    const response = await axios.get(`${this.baseUrl}${url}`, { params: params });
    Object.assign(this.stickyHeaders, response.headers);

    return response.data;
  }

  async post(url, { headers: postHeaders, ...data }, info) {
    const headers = {
      ...this.stickyHeaders,
      ...this.commonHeaders,
      ...(postHeaders ?? {}),
    };

    // return console.log(headers);
    const response = await axios.post(`${this.baseUrl}${url}`, data, {
      headers,
    }).catch(error => { throw error })
    return info === 'more-info' ? response : response.data;
  }


  async patch(url, { headers, ...data }) {

    const response = await axios.patch(`${this.baseUrl}${url}`, data, {
      headers: {
        ...this.stickyHeaders,
        ...this.commonHeaders,
        ...(headers ?? {}),
      },
    });
    return response.data;
  }

}
