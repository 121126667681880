import React, { useMemo } from 'react';
import carita_excelente from '../assets/excelente.png';
import carita_bueno from '../assets/bueno.png';
import carita_regular from '../assets/regular.png';
import carita_mal from '../assets/mal.png';
import pesimo from '../assets/malisimo.png';
import get from 'lodash/get';
import set from 'lodash/set';
import { useI18n } from '../utils/i18n';
import { useTheme } from '../hooks/sdk';

const smiles5 = [
  {
    value: 5,
    score: 10,
    alt: 'excellent',
    src: carita_excelente,
  },
  {
    value: 4,
    score: 7.25,
    alt: 'good',
    src: carita_bueno,
  },
  {
    value: 3,
    score: 5.5,
    alt: 'regular',
    src: carita_regular,
  },
  {
    value: 2,
    score: 3.25,
    alt: 'bad',
    src: carita_mal,
  },
  {
    value: 1,
    score: 1,
    alt: 'worst',
    src: pesimo,
  },
  {
    value: 0,
    score: null,
    alt: 'NA',
    src: 'image_na',
  },
];

const smiles = [
  {
    value: 4,
    score: 10,
    alt: 'excellent',
    src: carita_excelente,
  },
  {
    value: 3,
    score: 7,
    alt: 'good',
    src: carita_bueno,
  },
  {
    value: 2,
    score: 4,
    alt: 'regular',
    src: carita_regular,
  },
  {
    value: 1,
    score: 1,
    alt: 'bad',
    src: carita_mal,
  },
  {
    value: 0,
    score: null,
    alt: 'NA',
    src: 'image_na',
  },
];

const QuestionScoreSmiles = ({ question, answer, settings }) => {
  const t = useI18n();
  const min = get(question, 'metadata.settings.score.min', 1);
  const max = get(question, 'metadata.settings.score.max', 4);

  const theme = useTheme();
  const {scores: themeScores, smiles: themeSmiles} = theme;

  const newSmiles = useMemo(() => {
    if (max === 4) return smiles;
    return smiles5;
  }, [max]);

  const order = get(question, 'metadata.settings.score.order', 'default');
  const showLabels = get(question, 'metadata.settings.score.showLabels', false);
  const labels = get(question, 'metadata.settings.score.labels', undefined);
  const filtered = labels
    ? newSmiles
        .filter((smile) => smile.value !== 0 || settings.allowScoreNA)
        .map((smile, index) => {
          const newSmile = smile;
          if (get(labels, index)) set(newSmile, 'alt', labels[index]);
          return newSmile;
        })
    : newSmiles.filter((smile) => smile.value !== 0 || settings.allowScoreNA);

  const { worst, best } = get(question, 'metadata.settings.score', []);

  if (order === 'reverse') {
    filtered.reverse();
  }

  function seleccionar(voto) {
    if (answer.disabled) return;
    let score = undefined;
    if (voto !== 0) {
      score = ((voto - min) * 9) / (max - min) + 1;
    } else {
      score = null;
    }
    answer.setScore(score);
  }

  function currentClassName(smile) {
    return answer && answer.score === undefined
      ? 'estadoInicial'
      : answer && answer.module.id === question.id && answer.score === smile.score
      ? 'select'
      : 'sinselect-gray';
  }

  const naSmile = settings.allowScoreNA ? 'n-a' : '';

  return (
    <div>
      <ul height="100px" className={`caritaStyle ${naSmile} count-${filtered.length}`}>
        {filtered.map((smile) => (
          <div key={smile.alt}>
            {themeScores ? (
              <i
                data-testid={question.id + '' + smile.score}
                className={[
                  'icon ' + get(themeScores, `${smile.score}.icon`),
                  currentClassName(smile)
                ].join(' ')}
                key={smile.value}
                onClick={() => seleccionar(smile.value)}
              />
            ) : themeSmiles ? (
              <li key={smile.src}>
                <img
                  data-testid={question.id + '' + smile.score}
                  src={t(get(themeSmiles, `${smile.score}.src`))}
                  alt={smile.alt}
                  className={currentClassName(smile)}
                  onClick={() => seleccionar(smile.value)}
                />
                {showLabels && <p className="labels">{t(smile.alt)}</p>}
              </li>
            ) : (
              <li key={smile.src}>
                <img
                  data-testid={question.id + '' + smile.score}
                  src={smile.value === 0 ? t(smile.src) : smile.src}
                  alt={smile.alt}
                  className={currentClassName(smile)}
                  onClick={() => seleccionar(smile.value)}
                />
                {showLabels && (
                  <p
                    data-testid={question.id + '' + smile.alt}
                    className="labels"
                  >
                    {t(smile.alt)}
                  </p>
                )}
              </li>
            )}
          </div>
        ))}
      </ul>
      <div className={'label-score'} style={{ padding: '0px 8px 0px 8px' }}>
        <div className={'description-score-worst'}>
          {order === 'reverse' ? worst : best}
        </div>
        <div className={'description-score-best'}>
          {order === 'reverse' ? best : worst}
        </div>
      </div>
    </div>
  );
};

export default QuestionScoreSmiles;
