import dayjs from 'dayjs';

export function isTimestampInMilliseconds(timestamp) {
    // If the timestamp is in miliseconds should be > 10^10
    return timestamp > 1e10;
}
  
export function convertToDayjs(timestamp) {
    if(isTimestampInMilliseconds(timestamp)) {
        return dayjs(timestamp);
    } else {
        return dayjs(timestamp * 1000);
    }
}
export function isTimestampValid(timestamp) {
    if((typeof timestamp !== 'string' && typeof timestamp !== 'number') || isNaN(timestamp)) {
        return false;
    }

    const date = convertToDayjs(timestamp);
    return date.isValid();
}

export function isTimestampInThePast(timestamp) {
    if(!isTimestampValid(timestamp)) {
        throw new Error('invalid-timestamp');
    }

    const date = convertToDayjs(timestamp);
    const now = dayjs();

    return date.isBefore(now);
}