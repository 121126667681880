import get from 'lodash/get';
import { COLORS, DEGRADED_COLORS } from '../../utils/constants';
import clsx from 'clsx'
import styles from './styles.module.scss'

const ButtonLayout = ({ question, completNumeric, numericValue, min, max, setNumericScore }) => {
  const withColor = get(question, 'metadata.settings.score.withColor', false);
  const degraded = get(question, 'metadata.settings.score.degraded', false);

  function getTypeScore(value, onlyScore){
    const score = ((value - min) * 9) / (max - min) + 1;

    if(onlyScore) return score;
    if(score >= 9){
      return 'promotor';
    } else if(score < 7){
      return 'detractor';
    } else if(value === 'N/A') {
      return 'notApply'
    } else {
      return 'neutral'
    }
  }

  const defineColor = (selected, score, property) => {
    const scoreType = getTypeScore(score); // [detractor, neutral, promotor, notApply] or score

    const degradedColor = DEGRADED_COLORS.reduce((prev, curr) => {
      if(typeof score === 'string') return undefined;
      return Math.abs(curr.score - getTypeScore(score, 'onlyScore')) < Math.abs(prev.score - getTypeScore(score, 'onlyScore')) ? curr : prev;
    });

    const color = degraded
    ? get(degradedColor, 'color', 'gray')
    : COLORS[scoreType];

    if(selected) {
      return property === 'background' ? 'white' : color
    }
    return property === 'background' ? color : 'white'
  }

  return (
    <ul className={'numericStyle button'}>
      {completNumeric.map(values => {
        return (
          <li
            style={{
              color: withColor && defineColor(numericValue === values.score, values.score, 'color'),
              background: withColor && defineColor(numericValue === values.score, values.score, 'background')
            }}
            className={
              clsx(
                numericValue === values.score && 'select-bordered',
                styles['numeric-border'],
                withColor && styles['numeric-border-color']
              )
            }
            key={values.score}
            data-testid={question.id+''+values.score}
            onClick={() => setNumericScore(values.score, min, max)}
          >
            {values.score === null ? 'N/A' : values.score}
          </li>
        );
      })}
    </ul>
  )
}

export default ButtonLayout;