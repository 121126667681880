/* eslint-disable no-case-declarations */
import { FeedbackSDK } from '@smile-labs/feedback-sdk';
import React, { createContext, useReducer, useContext, useEffect, useMemo, useState, useImperativeHandle } from 'react';
import env from '../env';
import Smilehelper from '../utils/Smilehelper';
import set from 'lodash/set';

const initialState = {
  state: {},
  data: {},
  session: {
    requestDone: false
  },
  answers: [],
  userContactData: [],
  userData: {
    telefono: '',
    comment: '',
    mail: '',
  },
  ui: {
    startIndex: 0,
    currentView: 'question',
    isDark: false,
  },
  instance: undefined,
}

const immute = (root, merge = true, path, payload) => {
  const steps = (path || '').split('.');

  const immuted = steps.reduce(({ parent, root }, step, index) => {
    const lastStep = index === steps.length - 1;

    if (lastStep) {
      parent[step] = merge ? {
        ...(parent[step] || {}),
        ...payload,
      } : payload;
    } else {
      parent[step] = {
        ...(parent[step] || {}),
      };
    }
    if (parent[step] === undefined) delete parent[step];
    return lastStep ? (root || parent) : {
      parent: parent[step],
      root: root || parent,
    }
  }, {
    parent: {
      ...root
    },
  });
  return immuted;
}

export const store = createContext(initialState);

export const StoreProvider = ({ children, storeRef }) => {
  const feedbackSDK = useMemo(() => new FeedbackSDK(), []);
  const instance = useMemo(() => feedbackSDK.createFeedbackInstance(), [feedbackSDK]);

  useEffect(() => {
    Smilehelper.feedbackSDK = feedbackSDK;
  }, [feedbackSDK]);

  useEffect(() => {
    feedbackSDK.baseUrl = env.REACT_APP_API_URI;
    env.REACT_APP_API_URI = feedbackSDK.baseUrl;

    if (env.DEBUG) {
      feedbackSDK.on('log', console.log);
      feedbackSDK.debug = true;
    }
  }, [feedbackSDK]);

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'update':
        return immute(state, true, action.path, action.payload);
      case 'data.update':
        return immute(state, true, 'data', action.payload);
      case 'session.update':
        return immute(state, true, 'session', action.payload);
      case 'ui.update':
        return {
          ...state,
          ui: {
            ...state.ui,
            ...action.payload,
          },
        };
      case 'ui.clear':
        return {
          ...state,
          ui: {
            ...state.ui,
            startIndex: 0,
          },
        };
      case 'userData.update':
        return {
          ...state,
          userData: {
            ...state.userData,
            ...action.payload,
          },
        };
      case 'userContactData.update':
        return {
          ...state,
          userContactData: [
            ...action.payload,
          ],
        };
      case 'userContactData.setResponse':
        const fieldName = action.payload.fieldName;
        const value = action.payload.value;
        const index = state.userContactData.findIndex((filter) => filter.name === fieldName);
        const field = state.userContactData[index];

        const payload = { ...state.userContactData[index], response: value };
        if(field?.validation) set(payload,'validated',action.payload.validated)
            
        state.userContactData[index] = payload;

        return {
          ...state,
          userContactData: [
            ...state.userContactData,
          ],
        };
      case 'userContactData.clear':
        return {
          ...state,
          userContactData: [
            ...initialState.userContactData,
          ],
        };

      default:
        throw new Error();
    };
  }, initialState);

  useImperativeHandle(storeRef, () => ({
    instance,
    state,
    dispatch
  }), [instance, state, dispatch]);

  return <store.Provider value={{ context: state, dispatch, instance }}>{children}</store.Provider>;
}

export const useStoreContext = () => useContext(store);

export const useFeedbackEventHandler = (event, handler) => {
  const { instance } = useStoreContext();

  useEffect(() => {
    return instance?.on(event, handler);
  }, [event, handler, instance]);
};

export const useEventMemo = (
  target,
  event,
  extractor
) => {
  const [memo, setMemo] = useState(extractor ? extractor(target) : undefined);

  useEffect(() => {
    if (extractor) {
      setMemo(extractor?.(target));
    }
    return target.on(event, (value) => {
      if (extractor) {
        setMemo(extractor?.(target));
      } else {
        setMemo(value);
      }
    });
  }, [event, extractor, target]);

  return memo;
};

export const useInstanceEvent = (
  event,
  extractor
) => {
  const { instance } = useStoreContext();

  return useEventMemo(instance, event, extractor);
};
