import { useMemo } from "react";
import { useInstanceEvent } from "../context/store";

export const useTheme = () => {
  const settings = useInstanceEvent('settings', instance => instance.settings);
  
  return useMemo(() => settings.ui.theme ?? {}, [settings]);
}

export const usePalette = () => {
  const { defaultPalette, lightPalette, darkPalette } = useTheme();

  return defaultPalette === 'dark' ? darkPalette : lightPalette;
}
