
import get from 'lodash/get';
import { useEffect } from 'react';
import { useStoreContext } from '../context/store';
import { usePalette, useTheme } from './sdk';

const useBodyStyle = () => {
  const { dispatch } = useStoreContext();

  const theme = useTheme();
  const palette = usePalette();

  useEffect(() => {
    const bodyBackgroundColor = get(palette, '--background-color');
    const bodyBackgroundImage = get(palette, '--background-image');

    const blur = get(palette, '--blur',1);

    const imageBackgroundStyle = `backdrop-filter: blur(${blur}px);`
    document.body.style = `
      background-color: ${bodyBackgroundColor};
      background: ${bodyBackgroundImage} no-repeat center center fixed;
      background-size:cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      ${bodyBackgroundImage ? imageBackgroundStyle : ''}
    `;

    dispatch({
      type: 'ui.update',
      payload: {
        isDark: theme.defaultPalette !== 'light',
      },
    });

  }, [dispatch, palette, theme.defaultPalette]);

}

export default useBodyStyle;
