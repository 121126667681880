import AuthService from './AuthService';

export const auth = new AuthService();

const Smilehelper = {
  postEventData({ payload }) {
    return auth.post('apiv3/rest/eventsContacts', payload);
  },
  updateFeedback: function(feedbackId, hash, response) {
    if (hash) return auth.patch(`api/form-data/${hash}`, {
      feedbackId,
      data: response,
    });
  },
  validateForm:function (hash, content) {
    return auth.post(`api/form-data/${hash}/validate`,content);
  },
  postDatos: function(hash, response) {
    if (Smilehelper?.feedbackSDK?.debug) {
      return console.log(`api/form-data/${hash || ''}`, response);
    }
    return auth.post(`api/form-data/${hash}`, {
      data: response,
      headers: {
        'X-Referer': response.url_from,
      },
    });
  },
};

export default Smilehelper;
