import { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';
import { useI18n } from '../../utils/i18n';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#ffffff',
      boxShadow: '#212121',
      padding: '10px',
      margin: '10px',
    },
    'modal-button': {
        fontWeight: 400,
        fontSize: '1rem',
        'border-radius': '10px',
        height: '30px',
        padding: '10px 20px 25px',
        backgroundColor: '#6063F6',
        borderColor: '#6063F6',
        '&:hover': {
            backgroundColor: '#3438DD',
            borderColor: '#3438DD'
          },
          '&:focus': {
            backgroundColor: '#3438DD',
            borderColor: '#3438DD'
          },
      },
      'container-button': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
      }
  }));

const SmileModal = ({ children, openModal }) => {
    const [isOpen, setIsOpen] = useState(false || openModal);
    const classes = useStyles();
  const t = useI18n();

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <div className={classes.paper}>
                    {children}
                    <div className={classes['container-button']}>
                        <Button className={classes['modal-button']} onClick={handleClose}>
                            {t('accept')}
                        </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

export default SmileModal;