import carita_na_en from './assets/na_en.png';
import carita_na_es from './assets/na_es.png';
import carita_na_pt from './assets/na_pt.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  en: {
    send: 'Send',
    select: 'Select',
    from: 'from',
    to: 'to',
    items: 'items',
    yes: 'yes',
    no: 'no',
    excellent: 'Excelent',
    excelent: 'Excelent',
    bad: 'Bad',
    regular: 'Regular',
    good: 'Good',
    worst: 'Worst',
    'very-unsatisfied': 'Very unsatisfied',
    unsatisfied: 'Unsatisfied',
    'neither-satisfied-nor-dissatisfied': 'Neither satisfied nor dissatisfied',
    satisfied: 'Satisfied',
    'very-satisfied': 'Very satisfied',
    'phone-number': 'Phone number',
    'let-comment': 'Let us your comment',
    'let-extra-information':
      'If you wish, you can leave us your email and / or cell phone so that we can contact you',
    'any-additional-comments': 'Any additional comments?',
    'main-reason-opinion': 'What is the main reason for your opinion?',
    'thanks-opinion': 'Thanks for your opinion',
    goodbye: 'Goodbye',
    'write-here': 'Write here...',
    'expired-poll': 'The survey you want to view is no longer available.',
    image_na: carita_na_en,
    first_name: 'First Name',
    last_name: 'Last Name',
    birth_date: 'Birthday',
    phone_number: 'Phone Number',
    identification: 'Identity document',
    email: 'Email',
    gender: 'Gender',
    nationality: 'Nationality',
    location: 'Location',
    attach: 'To attach',
    'stores': 'Stores',
    'invalid-size':
      'You have exceeded the allowed image size (5 MB). Try another image.',
    'invalid-format': 'The file format is invalid',
    'very-difficult':'Very Difficult',
    'hard':'Hard',
    'indistinct':'Indistinct',
    'easy':'Easy',
    'very-easy':'Very Easy',
    'write-question':'Write the question',
    'write-title': 'Write title',
    'invalid-date': 'Invalid date',
    'accept': 'Accept',
    'cancel': 'Cancel',
    'maximum-date-exceeded': 'Maximum date exceeded',
    'minimum-date-exceeded': 'Minimum date exceeded',
    'female':'Female',
    'male':'Male',
    'other':'Other',
    'not-apply': 'Not apply',
    'validate':'Validate',
    'max-motives-selected': 'You have selected the maximum number of motives.',
    errors: {
      'complete-question': 'Please, complete the question',
      'complete-comment': 'Please, complete the comment',
      'complete-motives': 'Please, complete motives',
      'complete-fields': 'Please, complete the field to continue',
      'complete-require-fields': 'Please, complete require fields',
      'enter-valid-email': 'Please, enter a valid email',
    },
  },
  es: {
    send: 'Enviar',
    select: 'Seleccione',
    from: 'desde',
    to: 'hasta',
    items: 'items',
    no: 'No',
    excellent: 'Excelente',
    excelent: 'Excelente',
    yes: 'Sí',
    bad: 'Malo',
    regular: 'Regular',
    good: 'Bueno',
    worst: 'Pésimo',
    'very-unsatisfied': 'Muy insatisfecho',
    unsatisfied: 'Insatisfecho',
    'neither-satisfied-nor-dissatisfied': 'Ni satisfecho ni insatisfecho',
    satisfied: 'Satisfecho',
    'very-satisfied': 'Muy satisfecho',
    'phone-number': 'Teléfono',
    'let-comment': 'Dejanos tu comentario',
    'any-additional-comments': '¿Algún comentario adicional?',
    'let-extra-information':
      'Si lo deseas podés dejarnos tu mail y/o celular para que nos podamos contactar con vos',
    'main-reason-opinion': '¿Cuál es el principal motivo de tu opinión?',
    'expired-poll': 'La encuesta que desea ver ya no se encuentra disponible.',
    'thanks-opinion': 'Gracias por tu opinión',
    goodbye: '¡Hasta la próxima!',
    'write-here': 'Escribe aquí...',
    'write-question':'Escriba su pregunta',
    'write-title': 'Escriba el título',
    image_na: carita_na_es,
    first_name: 'Nombre',
    last_name: 'Apellido',
    birth_date: 'Fecha de nacimiento',
    phone_number: 'Número de teléfono',
    identification: 'Documento de identidad',
    email: 'Email',
    gender: 'Género',
    nationality: 'Nacionalidad',
    location: 'Localidad',
    attach: 'Adjuntar',
    'stores': 'Sucursales',
    'invalid-size':
      'Has excedido el tamaño de imagen permitido (5 MB). Intenta con otra imagen.',
    'invalid-format': 'El formato del archivo es inválido',
    'hard':'Difícil',
    'very-difficult':'Muy difícil',
    'indistinct':'Indistinto',
    'easy':'Fácil',
    'very-easy':'Muy fácil',
    'something-difficult':'Algo difícil',
    'not-easy-not-difficult':'Ni fácil, ni difícil',
    'almost-easy':'Casi fácil',
    'invalid-date': 'Fecha inválida',
    'accept': 'Aceptar',
    'cancel': 'Cancelar',
    'maximum-date-exceeded': 'Fecha máxima sobrepasada',
    'minimum-date-exceeded': 'Fecha mínima sobrepasada',
    'female':'Femenino',
    'male':'Masculino',
    'other':'Otro',
    'not-apply': 'No aplica',
    'validate':'Validar',
    'max-motives-selected': 'Has seleccionado la cantidad máxima de duplas.',
    errors: {
      'complete-question': 'Por favor, complete la pregunta',
      'complete-comment': 'Por favor, complete el comentario',
      'complete-motives': 'Por favor, complete los motivos',
      'complete-fields': 'Por favor, complete el campo para continuar',
      'complete-require-fields': 'Por favor, complete los campos requeridos',
      'enter-valid-email': 'Por favor, ingresa un email válido',
    },
  },
  pt: {
    send: 'Enviar',
    select: 'Selecione',
    from: 'desde',
    to: 'ate',
    items: 'Itens',
    yes: 'Sim',
    no: 'Não',
    excellent: 'Excelente',
    excelent: 'Excelente',
    bad: 'Ruim',
    regular: 'Regular',
    good: 'Bon',
    worst: 'Terrível',
    'very-unsatisfied': 'Muito Insatisfeito',
    'write-question':'Escreva sua pergunta',
    'write-title':'Escreva o título',
    unsatisfied: 'Insatisfeito',
    'neither-satisfied-nor-dissatisfied': 'nem satisfeito nem insatisfeito',
    satisfied: 'Satisfeito',
    'very-satisfied': 'Muito satisfeito',
    'phone-number': 'Telefone',
    'let-comment': 'Deixe-nos o seu comentário',
    'let-extra-information':
      'Se desejar, pode nos deixar seu e-mail e / ou celular para que possamos entrar em contato com você',
    'any-additional-comments': 'Algum comentário adicional?',
    'main-reason-opinion': 'Qual é o principal motivo da sua opinião?',
    'thanks-opinion': 'Obrigado por deixar a sua opinião',
    goodbye: 'Até a próxima!',
    'write-here': 'Escreva aqui...',
    'expired-poll':
      'A pesquisa que você deseja visualizar não está mais disponível.',
    image_na: carita_na_pt,
    first_name: 'Nome',
    last_name: 'Sobrenome',
    birth_date: 'Data de Nascimento',
    phone_number: 'Número de telefone',
    identification: 'Documento de identidade',
    email: 'Email',
    gender: 'Gênero',
    nationality: 'Nacionalidade',
    location: 'Localização',
    attach: 'Anexar',
    'stores': 'Filiales',
    'invalid-size':
      'Você excedeu o tamanho de imagem permitido (5 MB). Tente outra imagem.',
    'invalid-format': 'O formato do arquivo é inválido',
    'very-difficult':'Muito difícil',
    'hard':'Difícil',
    'indistinct':'Indiferente',
    'easy':'Fácil',
    'very-easy':'Muito fácil',
    'something-difficult':'Um pouco difícil',
    'not-easy-not-difficult':'Mais ou menos',
    'almost-easy':'Um pouco fácil',
    'invalid-date': 'Data inválida',
    'accept': 'Aceitar',
    'cancel': 'Cancelar',
    'maximum-date-exceeded': 'Data máxima excedida',
    'minimum-date-exceeded': 'Data mínima excedida',
    'female':'Feminino',
    'male':'Masculino',
    'other':'Outro',
    'not-apply':'Não aplica',
    'validate':'Validar',
    'max-motives-selected': 'Você selecionou o número máximo de motivos.',
    errors: {
      'complete-question': 'Por favor complete a questão',
      'complete-comment': 'Por favor, complete o comentário',
      'complete-motives': 'Por favor, preencha os motivos',
      'complete-fields': 'Por favor, preencha o campo para continuar',
      'complete-require-fields': 'Por favor, preencha os campos obrigatórios',
      'enter-valid-email': 'Por favor, digite um email válido',
    },
  },
};
