import { useInstanceEvent } from "../../context/store";
import { usePalette } from "../../hooks/sdk";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const ProgressBar = () => {
  const { progress } = useInstanceEvent('nav', (instance) => instance.nav);
  const palette = usePalette();

  const progressBarColor = palette['--progressBar-color'] ?? '#4bc3b4';

  const BarProgress = withStyles((theme) => ({
    root: {
      height: 8,
      borderRadius: 5,
      width: '90%',
      margin: 'auto'
    },
    colorPrimary: {
      backgroundColor: '#f5f5f5'
    },
    bar: {
      borderRadius: 5,
      backgroundColor: progressBarColor,
    },
  }))(LinearProgress);

  return (
    <BarProgress
      sx={{ width: '90%', alignSelf: 'center' }}
      value={progress.percentage}
      data-testid='progress-bar-test'
      variant="determinate"
    />
  );
}

export default ProgressBar;
