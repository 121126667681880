import React from 'react';
import { useI18n } from '../../utils/i18n';

export const Legends = ({ worst, best, size }) => {
  const t = useI18n();

  return (
    <div className={'label-score'} style={{ padding: '0px 8px 0px 8px' }}>
      <div className={'description-score-worst'} style={{fontSize: size}} data-testid='legend-worst'>{t(worst)}</div>
      <div className={'description-score-best'} style={{fontSize: size}} data-testid='legend-best'>{t(best)}</div>
    </div>
  );
};