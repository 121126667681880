import get from 'lodash/get';
import React, { useEffect } from 'react';
import YouTube from 'react-youtube';

const defaultProps = {
  playerVars: {
    autoplay:1,
    controls: 0,
    disablekb: 1,
    iv_load_policy: 3,
    modestbranding: 0,
    rel: 0,
    showInfo: 0,
  },
};

export const Media = ({ onEnd, url, type, settings}) => {
  const props = {playerVars:{...defaultProps.playerVars,...settings}};

  useEffect(()=>{
    if(get(props,'playerVars.autoplay') !== 1)return;
    const view= document.getElementById('root');
    view.click();
  },[]);

  const getVideoId = () => {
    if (url?.includes('youtube.com/watch?v')) {
      return url.split('v=')[1].split('&')[0];
    }
    if (url.includes('youtu.be/')) {
      return url.split('youtu.be/')[1].split('?')[0];
    }
    return '';
  }

  return (
    <>
      {type === 'youtube' && url && (
        <YouTube
          iframeClassName="embed-player"
          onEnd={() => onEnd()}
          videoId={getVideoId()}
          opts={props}
        />
      )}
    </>
  );
};
