import React, { useMemo, useState } from 'react';
import Select from '@material-ui/core/Select';
import {
  MenuItem,
  InputLabel,
  FormControl,
  ListSubheader,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useI18n } from '../utils/i18n';

const SmileSelect = ({
  dataTestId = 'SmileSelect',
  label,
  value,
  required,
  onChange,
  disabled = false,
  options,
  showError,
  style = {},
  autocomplete = false,
}) => {
  const [searchText, setSearchText] = useState(undefined);

  const defaultCaptions = ['male', 'female', 'other'];
  const t = useI18n();

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(() => {
    if(!searchText) return [...options];
    return options.filter((option) => containsText(option.caption, searchText));
  }, [searchText, options]);
  return (
    <FormControl disabled={disabled} required={required} size="small" variant="outlined" fullWidth>
      <InputLabel disabled={disabled} style={style} id="demo-simple-select-outlined-label">
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        data-testid={dataTestId}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        defaultValue={undefined}
        value={value}
        onChange={onChange}
        label={label}
        style={{ ...style, textAlign: 'left',background:disabled?'lightgrey':'initial' }}
        error={showError}
      >
        {autocomplete && (
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              disabled={disabled}
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <SearchIcon /> */}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions.map((option) => {
          let caption = option.caption;
          if (defaultCaptions.includes(caption)) {
            caption = t(caption);
          }

          return (
            <MenuItem key={option.value} value={option.value}>
              {caption}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
    /* <SmSelect options={options} value={value} required={required} label={label} onChange={onChange}/> */
  );
};

export default SmileSelect;
