import get from 'lodash/get';
import './styles/alert.css';
import { AiFillCloseCircle } from 'react-icons/ai';
import { AiFillCheckCircle } from 'react-icons/ai';

const SmileAlert = ({ message, type, children }) => {

    const types = {
        error: {
            border: '#FFCCC7',
            background: '#FFF2F0',
            icon: '#FF4D4F'
        },
        success: {
            border: '#CFF3B3',
            background: '#F6FFED',
            icon: '#52C41A'
        },
        warning: {
            border: '#FFE58F',
            background: '#FFFBE6',
            icon: '#D48806'
        }
    }

    const styles = {
        borderColor: get(types, `${type}.border`),
        backgroundColor: get(types, `${type}.background`),
        justifyContent: type === 'success' ? 'space-between': ''
    }

    return (
        <div style={styles} className='smile-alert-container'>
            <div className='alert-icon'>
                {type === 'error'
                ? <AiFillCloseCircle size={17} style={{color: get(types, `${type}.icon`)}} />
                : <AiFillCheckCircle size={17} style={{color: get(types, `${type}.icon`)}} />}
            </div>
            <div>
                {message}
            </div>
            {children ? <div>
                {children}
            </div> : null}
        </div>
    );
}

export default SmileAlert;