import {
    AiOutlineStar,
    AiFillStar,
    AiFillHeart,
    AiOutlineHeart
} from 'react-icons/ai';
import '../styles/smile-rate.css';
import { useState } from 'react';

const icons = {
    StarFilled: <AiFillStar />,
    StarOutlined: <AiOutlineStar />,
    HeartFilled: <AiFillHeart />,
    HeartOutlined: <AiOutlineHeart />,
};

const SmileRate = ({ max = 0, icon, onChange, value = 0, style }) => {
    const { color, fontSize } = style;

    const [hoverIndex, setHoverIndex] = useState(-1);

    const handleIconHover = (index) => {
        setHoverIndex(index);
    }

    const handleIconLeave = () => {
        setHoverIndex(-1);
    }

    const changeColor = (index) => {
        if (index <= hoverIndex || index + 1 <= value) {
          return color ?? '#FFB400';
        } else {
          return '#BDBDBD';
        }
      }

    return (
        <div className='smile-rate-container'>
            {Array(max).fill().map((_, index) => (
                <div
                    key={index}
                    className='smile-rate-icon'
                    style={{color: changeColor(index), fontSize: fontSize}}
                    onMouseEnter={() => handleIconHover(index)}
                    onMouseLeave={handleIconLeave}
                    onClick={(e) => onChange(index + 1)}
                >
                    <div>{icons[icon] ? icons[icon] : icons.StarFilled}</div>
                </div>
            ))}
        </div>
     );
}

export default SmileRate;