import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import './index.css';
import env, { addJar } from './env'
import * as serviceWorker from './serviceWorker';
import envJson from './env.json';

import App from './App';

(async () => {

  await addJar({
    REACT_APP_API_URI: 'https://prod-api.smileweb.net/',
    REACT_APP_BASENAME: '/',
  });
  await addJar(envJson);

  await addJar(process.env);

  // env.SENTRY_DSN && Sentry.init({
  //   dsn: env.SENTRY_DSN,
  
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
  
  //   integrations: [
  //     new BrowserTracing(),
  //     new Sentry.Replay({}),
  //   ],
  
  //   tracesSampleRate: 1.0,
  // });

  ReactDOM.render(<App />, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
