import { log } from "./debug";

class Bridge {
  constructor() {
    this.defaultTarget = {};
    this.listeners = [];
    window.addEventListener('message', this.onMessage.bind(this), false);
  }
  emit(type, payload) {
    const { contentWindow, origin } = this.defaultTarget;
    log('bridge.emit()', type, payload, {
      contentWindow
    });
    if (!contentWindow) return;
    contentWindow.postMessage(
      JSON.stringify({
        type,
        payload,
      }), origin
    );
  }
  setDefaultTarget(defaultTarget) {
    this.defaultTarget = defaultTarget;
  }
  onMessage(ev) {
    try {
      const { data: rawData, detail: rawDetail } = ev;
      const { type, payload } = JSON.parse(rawData || rawDetail);
      log('bridge.onMessage()', type, payload, this);
      
      this.listeners
        .filter((listener) => listener.type === type)
        .map((listener) => listener.handler(payload));

    } catch (err) {

    }
  }
  on(type, handler) {
    try {
      this.listeners.push({
        type,
        handler,
      });
    } catch(err) {}
  }
  off() {
    this.listeners = [];
  }
  destroy() {
    window.removeEventListener('message', this.onMessage)
  }
  dispatchEvent(type, detail) {
    window.dispatchEvent(new CustomEvent(type, {
      detail,
    }));

  }
}

export const bridge = new Bridge();

export default Bridge;
