import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useMergeableState = (initial, options = {}) => {
  const [payload, setPayload] = useState(initial || {});

  function mergePayload(newPayload) {
    setPayload({
      ...payload,
      ...newPayload,
    });
  }
  return [
    payload,
    mergePayload,
  ];
}

export const useChangeLanguage = ({ language }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!language) return null;
    i18n.changeLanguage(language);
  }, [language, i18n]);
}
