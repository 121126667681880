import { TextField } from '@material-ui/core';

import React from 'react';

const SmileInput = ({ type, label, value, required, onChange, showError, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      id={label}
      key={type}
      type={type}
      label={label}
      required={required}
      value={value?value:''}
      onChange={onChange}
      variant="outlined"
      size={label?.length>30 ? "medium":"small"}
      inputProps={{ "data-testid": `form${type}` }}
      error={showError}
      fullWidth
    />
  );
};

export default SmileInput;