/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useStoreContext } from '../../context/store';
import { replaceVariables } from '../../utils/replaceVariables';

const Presentation = ({ informationModule, question }) => {
  const { context } = useStoreContext();
  const { poll, store, contact, campaign } = context.data;
  const { metadata } = poll;
  const informationPath = get(question, 'metadata.settings', '');

  const { title, subtitle, text, image } = useMemo(() => {
    return get(informationModule ? informationPath : metadata, informationModule ? 'information' : 'settings.presentation', {});
  }, [metadata, informationModule, informationPath]);

  return (
    <div
      id="presentation"
      data-testid="presentation"
      style={{ marginTop: '50px', padding: '0px 20px 0px 20px' }}
    >
      {get(image, 'src') && <img
        src={get(image, 'src')}
        alt='presentation'
        style={{
          marginBottom: get(image, 'marginBottom'),
          marginTop: get(image, 'marginTop'),
          width: get(image, 'width', '100%'),
          height: get(image, 'height', '100%')
        }}
      />}
      {title && (
        <h3 data-testid="presentation-title" className={'presentation-title'}>
          {replaceVariables(title, store, contact, campaign)}
        </h3>
      )}
      {subtitle && <h5 className={'presentation-subtitle'}>{replaceVariables(subtitle, store, contact, campaign)}</h5>}
      {text && <div
        className={'presentation-text'}
        style={{ whiteSpace: 'pre-wrap', fontSize: '19px' }}
        data-testid='presentation-text'
      >
        {replaceVariables(text, store, contact, campaign)}
      </div>}
    </div>
  );
};

export default Presentation;
