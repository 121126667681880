import get from 'lodash/get';

const Footer = ({ question }) => {
    return (
        <div className='footer-text-container'>
            {get(question, 'metadata.settings.score.footer.type', false) === 'link'
                ? <a
                    href={get(question, 'metadata.settings.score.footer.link')}
                    target='_blank'
                    rel='noreferrer'
                    style={{textDecoration: 'underline', color: '#008DFF', fontSize: '18px', fontWeight: 600}}
                >
                    {get(question, 'metadata.settings.score.footer.text')}
                </a>
                : <h5>{get(question, 'metadata.settings.score.footer.text')}</h5>
            }
        </div>
    );
}

export default Footer;
