import React from 'react';
import get from 'lodash/get';
import { useStoreContext } from '../context/store';
import { useI18n } from '../utils/i18n';

const UserDataFields = ({ encuesta, setUserData, userData }) => {
  const t = useI18n();
  const { context } = useStoreContext();
  const { showErrors } = context.data;
  const showDataFields = get(encuesta, 'metadata.settings.showDataFields', []);
  const requiredDataFields = get(
    encuesta,
    'metadata.settings.requiredDataFields',
    []
  );

  function showSpecificError(field) {
    if (
      showErrors &&
      requiredDataFields.includes(field) &&
      get(userData, field, []).length === 0
    ) {
      return <p className={'error'}>* {t('errors.complete-fields')}</p>;
    } else return null;
  }

  return (
    <div>
      <div className={'infoFields'}>
        <p>{t(encuesta.strings.titleFields)}</p>
      </div>
      {showDataFields.includes('mail') || showDataFields.length === 0 ? (
        <div className={'userDataField'}>
          <input
            onChange={(ev) => setUserData({ mail: ev.target.value })}
            type="email"
            placeholder="Mail"
            className={'input2'}
          />
          {showSpecificError('mail')}
        </div>
      ) : null}

      {showDataFields.includes('telefono') || showDataFields.length === 0 ? (
        <div className={'userDataField'}>
          <input
            onChange={(ev) => setUserData({ telefono: ev.target.value })}
            type="number"
            placeholder={t('phone-number')}
            className={'input2'}
          />
          {showSpecificError('telefono')}
        </div>
      ) : null}
    </div>
  );
};

export default UserDataFields;
