import get from 'lodash/get'
import clsx from 'clsx';

const ImageMotives = ({ settings, motive }) => {
    const heightSize = get(settings, 'heightSize', null);
    const widthSize = get(settings, 'widthSize', null);
    const baseFontSizeInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const heightInRem = heightSize / baseFontSizeInPixels;
    const widthInRem = widthSize / baseFontSizeInPixels;

    function calculateRatio(option, knownValue) {
        if (option === 'width') {
            return (22 / 2) * knownValue;
        } else if (option === 'height') {
            return knownValue / (2 / 36);
        } else {
            return null;
        }
    }

    return (
        <div style={{ display: 'grid' }}>
        <div className={clsx('image-container',( heightSize || widthSize) && 'custom-image-container')}>
            <img
              style={{
                marginBottom: '10px',
                maxWidth: (widthSize || heightSize) ? null : '100%',
                maxHeight: (widthSize || heightSize) ? null : '100%',
                height: heightSize ? `${heightInRem}rem` : (widthSize ? calculateRatio('height', widthInRem) : null),
                width: widthSize ? `${widthInRem}rem` : (heightSize ? calculateRatio('width', heightInRem) : null)
              }}
              src={get(motive, 'metadata.settings.image.src')}
              alt='motive'
            />
        </div>
      </div>
    );
}

export default ImageMotives;