import React from 'react';
import get from 'lodash/get';
import { useI18n } from '../utils/i18n';

const QuestionScoreRadio = ({ question, value, onChange, settings }) => {
  const t = useI18n();

  const radiosDefault = [
    {
      value: 1,
      score: 10,
      text: 'excelence',
    },
    {
      value: 2,
      score: 7,
      text: 'good',
    },
    {
      value: 3,
      score: 4,
      text: 'regular',
    },
    {
      value: 4,
      score: 1,
      text: 'Bad',
    },
    {
      value: 0,
      text: 'NA',
    }
  ];

  const radios = get(settings, 'radios', radiosDefault);
  const layout = get(settings, 'radioLayout', 'default');
  const filtered = radios.filter((radio) => radio.value !== 0 || settings.allowScoreNA);

  function seleccionar(voto) {
    const radio = radios.find((radio) => radio.value === voto);
    onChange({
      ...value,
      voto,
      score: radio ? radio.score : undefined,
    });
  }

  return (
    <ul
      style={layout === "list" ? ({ display: 'grid', justifyContent: 'left', paddingLeft: '43px' }) : null}
      className={`inputStyle count-${filtered.length}`}>
      {filtered.map((radio) => {
        return (
          <div key={radio.text}
            style={layout === "list" ? ({ display: 'flex', margin: '0px', justifySelf: 'left', cursor: 'pointer' }) : ({ margin: 'auto', display: 'grid' })}
            onClick={() => seleccionar(radio.value)}
            className={
              value && value.voto === undefined
                ? 'estadoInicial'
                : value &&
                  value.module.id === question.id &&
                  value.voto === radio.value
                  ? 'select'
                  : 'sinselect'
            }
          >
            {layout === "default" ? t(radio.text) : null}
            <li key={radio.text}>
              <div
                style={layout === "list" ? ({ marginRight: '5px' }) : null}
                className={
                  value && value.voto === undefined
                    ? 'estadoInicial'
                    : value &&
                      value.module.id === question.id &&
                      value.voto === radio.value
                      ? 'select'
                      : 'sinselect'
                }>
              </div>
            </li>
            {layout === "list" ? t(radio.text) : null}
          </div>
        );
      })}
    </ul>
  );
};
export default QuestionScoreRadio;