/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useI18n } from '../utils/i18n';
import clsx from 'clsx';
import SmileAlert from './ui/Alert';
import SmileModal from './ui/SmileModal';
import ImageMotives from './ImageMotives';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const Motives = ({
  question,
  strings,
  settings,
  value: answer,
  showErrors,
  errors,
}) => {
  const [indexInput, setIndex] = useState(-1);
  const t = useI18n();
  const [placeholderInput, setPlaceHolderInput] = useState(t('write-here'));
  const [showInput, setShowInput] = useState(false);
  const [maxImagesSelected, setMaxImagesSelected] = useState({
    maxSelected: false,
    ids: [],
  });

  const imageLayout = get(settings, 'motivesLayout', '') === 'image';
  const disableWhenMax = get(settings, 'disableWhenMax', false);
  const showAlertMaxMotives = get(settings, 'showAlertMaxMotives', false);
  const uniqueMotiveSelection = get(settings, 'uniqueMotiveSelection', false);
  const orderMotives = get(settings, 'orderMotives', false);

  const motivesLayout = settings.motivesLayout;
  const motivesQuestion = get(question, 'metadata.settings.notApplyOption')
    ? [
        ...question.motives,
        {
          name: get(
            question,
            'metadata.settings.notApplyOptionText',
            t('not-apply')
          ),
          id: null,
          ...(motivesLayout === 'image' && {
            metadata: {
              settings: {
                image: {
                  src: get(question, 'metadata.settings.notApplyImage'),
                },
              },
            },
          }),
        },
      ]
    : question.motives;

  const leyend = get(settings, 'leyendMotives')
    ? settings.leyendMotives
    : `[${t('select')} ${
        settings.minMotivos ? `${t('from')} ${settings.minMotivos}` : ''
      } ${settings.maxMotivos ? `${t('to')} ${settings.maxMotivos}` : ''} ${t(
        'items'
      )}]`;

  useEffect(() => {
    // Para mostrar el input
    const selectedMotives = get(answer, 'motives', []);
    let find = false;
    selectedMotives.forEach((motiveResponse, index) => {
      const motive = get(question, 'motives', []).find(
        (motive) => motiveResponse.id === motive.id
      );
      if (find) return;
      if (get(motive, 'metadata.settings.showInput', false)) {
        const findIndex = motivesQuestion.findIndex(
          (motiveQuestion) => motiveQuestion.id === motiveResponse.id
        );
        find = true;
        setShowInput(true);
        setIndex(findIndex);
      } else {
        setShowInput(false);
      }
      if (get(motive, 'metadata.settings.placeholder', false)) {
        setPlaceHolderInput(
          get(motive, 'metadata.settings.placeholder', t('write-here'))
        );
      }
    });

    if (selectedMotives.length === 0) setShowInput(false);
  }, [get(answer, 'motives'), question, t]);

  function seleccionarMotivo(motiveId, index) {
    if (orderMotives) return;

    let motives = [...get(answer, 'motives', [])];

    const motiveSelected = motives.find((motive) => motive.id === motiveId);

    if (
      answer.disabled ||
      (maxImagesSelected.maxSelected &&
        !maxImagesSelected.ids.includes(motiveId))
    )
      return;

    if (motiveSelected) {
      motives = motives.filter(
        (filteredMotive) => filteredMotive.id !== motiveId
      );
    } else {
      if (motiveId === null) {
        motives = [{ id: null }];
      }
      if (uniqueMotiveSelection && motiveId === uniqueMotiveSelection) {
        motives = [{ id: motiveId }];
      } else {
        motives.push({ id: motiveId, comment: null });
      }
    }

    if (motiveId) {
      motives = motives.filter((motive) => motive?.id !== null);
    }

    if (uniqueMotiveSelection && motiveId !== uniqueMotiveSelection) {
      motives = motives.filter(
        (motive) => motive?.id !== uniqueMotiveSelection
      );
    }
    const maxMotivos = settings.maxMotivos;
    motives = motives.slice(-maxMotivos);

    if (motives.length !== 0) {
      answer.setMotives(motives, {
        preventDirty: showInput,
      });
    }

    if (imageLayout && disableWhenMax) {
      const motiveIds = motives?.map((motive) => motive?.id);
      const selectedObj = {
        maxSelected: (motives || []).length === maxMotivos,
        ids: motiveIds,
      };
      setMaxImagesSelected(selectedObj);
    }
  }

  useEffect(() => {
    if (!orderMotives) return;
    if (answer.motives.length > 0) return;
    const motives = question.motives.map((motive) => {
      return {
        id: motive.id,
        ui_name: motive.ui_name,
        name: motive.name,
      };
    });
    answer.setMotives(motives);
  }, [orderMotives]);

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) return;
    const newMotives = [...answer.motives];

    const motiveDragable = newMotives[source.index];
    newMotives.splice(source.index, 1);
    newMotives.splice(destination.index, 0, motiveDragable);
    answer.setMotives(newMotives);
  }

  const motivesSelected = [...get(answer, 'motives', [])];
  const commentLength = settings.commentLength;

  return (
    <>
      {/* ALERT MAX MOTIVES */}
      {showAlertMaxMotives && motivesSelected.length === settings?.maxMotivos && (
        <SmileModal openModal={showAlertMaxMotives}>
          <SmileAlert message={t('max-motives-selected')} type="warning" />
        </SmileModal>
      )}
      {/* TITULO DE MOTIVES */}
      {question.score_type != null && get(question, 'motives', []).length > 1 && (
        <div style={{ fontSize: '18px', margin: '0px' }} className={'pregunta'}>
          <div
            style={{ maxWidth: '410px', margin: '0px' }}
            className={'preguntaComentario'}
          >
            {t(strings.motivosTitle)}
          </div>
        </div>
      )}
      {/* CANTIDAD MAXIMA DE MOTIVOS */}
      {(settings.maxMotivos > 1 || settings.minMotivos) && (
        <div className={'motives-max-quantity'}>{leyend}</div>
      )}

      <div className={`tabla ${motivesLayout}`}>
        {get(question, 'motives', []).length >= 2 &&
          motivesLayout === 'default' && (
            <div className={'motivos'}>
              {motivesQuestion.map((motive, index) => {
                const motives = get(answer, 'motives', []);
                const answerIndex = motives.findIndex(
                  (motiveAnswer) => motiveAnswer.id === motive.id
                );
                return (
                  <div>
                    <button
                      key={`${question.id}motive${motive.id}`}
                      data-testid={`${question.id}motive${motive.id}`}
                      style={{ margin: '5px' }}
                      className={
                        answer &&
                        get(answer, 'motives').find(
                          (motivoSelect) => motivoSelect.id === motive.id
                        )
                          ? 'motivo selected'
                          : 'motivo'
                      }
                      onClick={() => seleccionarMotivo(motive.id)}
                    >
                      {motive.ui_name || motive.name}
                    </button>
                    {showInput && indexInput === index && (
                      <>
                        <input
                          placeholder={t(placeholderInput)}
                          className={`inputMotive ${motivesLayout}`}
                          data-testid={`${question.id}motiveInput`}
                          value={
                            get(
                              get(answer, `motives.${answerIndex}`),
                              'comment'
                            ) || ''
                          }
                          onChange={(ev) => {
                            if (answer.disabled) return;
                            const motives = get(answer, 'motives', []);
                            const motiveAnswer = motives.find(
                              (motiveAnswer) => motiveAnswer.id === motive.id
                            );
                            answer.updateMotive({
                              ...motiveAnswer,
                              comment: ev.target.value,
                            });
                          }}
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          )}

        {get(question, 'motives', []).length >= 2 &&
          motivesLayout !== 'default' && (
            <div className={'motivos-' + motivesLayout}>
              <DragDropContext onDragEnd={onDragEnd}>
                {(orderMotives ? answer.motives : motivesQuestion).map(
                  (motive, index) => {
                    const content = (
                      <Motive
                        showInput={showInput}
                        key={`${motive.id}-${index}`}
                        question={question}
                        motive={motive}
                        answer={orderMotives ? {} : answer}
                        maxImagesSelected={maxImagesSelected}
                        motivesLayout={motivesLayout}
                        seleccionarMotivo={seleccionarMotivo}
                        settings={settings}
                        order={orderMotives ? index + 1 : null}
                      />
                    );

                    if (orderMotives)
                      return (
                        <Droppable
                          droppableId={`dropable ${index}`}
                          key={index}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <Draggable
                                key={index}
                                index={index}
                                draggableId={`dragable ${index}`}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {content}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      );

                    const motives = get(answer, 'motives', []);
                    const answerIndex = motives.findIndex(
                      (motiveAnswer) => motiveAnswer.id === motive.id
                    );
                    return (
                      <div>
                        {content}
                        {showInput && indexInput === index && (
                          <div>
                            <input
                              placeholder={t(placeholderInput)}
                              className={`inputMotive ${motivesLayout}`}
                              data-testid={`${question.id}motiveInput`}
                              value={
                                get(
                                  get(answer, `motives.${answerIndex}`),
                                  'comment'
                                ) || ''
                              }
                              onChange={(ev) => {
                                if (answer.disabled) return;
                                const motives = get(answer, 'motives', []);
                                const motiveAnswer = motives.find(
                                  (motiveAnswer) =>
                                    motiveAnswer.id === motive.id
                                );
                                answer.updateMotive({
                                  ...motiveAnswer,
                                  comment: ev.target.value,
                                });
                              }}
                            />
                            <div className={`blank-space  ${motivesLayout}`} />
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </DragDropContext>
            </div>
          )}
        {settings.commentEnabled && (
          <div>
            <div className={'pregunta caja'}>
              {settings.commentLayout === 'mini' ? (
                <div>
                  <input
                    disabled={answer.disabled}
                    style={{ height: 'unset' }}
                    value={get(answer, 'comment') || ''}
                    onChange={(ev) => answer.setComment(ev.target.value)}
                    className={'input'}
                    data-testid={`short-comment${question.id}`}
                    maxLength={commentLength}
                  />
                  {commentLength && (
                    <div className='comment-length'>{`${
                      get(answer, 'comment').length
                    }/${commentLength}`}</div>
                  )}
                </div>
              ) : (
                <textarea
                  disabled={answer.disabled}
                  data-testid={`comment${question.id}`}
                  value={get(answer, 'comment') || ''}
                  onChange={(ev) => answer.setComment(ev.target.value)}
                  className={'input'}
                />
              )}
            </div>
            {showErrors && get(errors, 'comment', []).length > 0 && (
              <p className={'error'}>* {t('errors.complete-comment')}</p>
            )}
          </div>
        )}
      </div>

      {get(question, 'motives', []).length >= 2 && strings.footerMotives && (
        <div style={{ fontSize: '18px' }} className={'pregunta'}>
          <p style={{ maxWidth: '439px' }} className={'preguntaComentario'}>
            {strings.footerMotives}
          </p>
        </div>
      )}

      {showErrors && get(errors, 'motive', []).length > 0 && (
        <div className={'error'}>* {t('errors.complete-motives')}</div>
      )}
    </>
  );
};

const Motive = ({
  question,
  motive,
  answer,
  seleccionarMotivo,
  motivesLayout,
  maxImagesSelected,
  settings,
  order,
  showInput,
}) => {
  const maxMotivos = get(settings, 'maxMotives', 1);
  const hideMotiveLabels = get(settings, 'hideMotivesLabels');
  return (
    <div
      key={`${question.id}motive${motive.id}`}
      data-testid={`${question.id}motive${motive.id}`}
      onClick={() => seleccionarMotivo(motive.id)}
      className={clsx(
        (answer &&
          get(answer, 'motives', []).find(
            (motivoSelect) => motivoSelect.id === motive.id
          )) ||
          order
          ? `motivo-${motivesLayout} selected ${showInput ? 'showInput' : ''}`
          : `motivo-${motivesLayout} ${showInput ? 'showInput' : ''}`,
        maxImagesSelected.maxSelected &&
          !maxImagesSelected.ids.includes(motive.id) &&
          'motive-disabled'
      )}
    >
      <div className={'not-show'}>
        {maxMotivos > 1 && answer && (
          <i
            className={
              get(answer, 'motives', []).find(
                (motivoSelect) => motivoSelect.id === motive.id
              )
                ? 'smile21-square-checked'
                : 'smile21-square-unchecked'
            }
          />
        )}
        {maxMotivos === 1 && answer && (
          <i
            className={
              get(answer, 'motives', []).find(
                (motivoSelect) => motivoSelect.id === motive.id
              )
                ? 'smile21-radio-checked'
                : 'smile21-radio-unchecked'
            }
          />
        )}
      </div>

      {motivesLayout === 'image' && (
        <ImageMotives settings={settings} motive={motive} />
      )}

      {!hideMotiveLabels && (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {order ? `${order}.` : ''} {motive.ui_name || motive.name}
        </span>
      )}
    </div>
  );
};

export default Motives;
