import i18next from 'i18next';
import { useEffect, useState } from 'react';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';
import resources from '../i18n';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {},
    fallbackLng: ['en', 'es'],
    detection: {},
    interpolation: {
      escapeValue: false
    }
  });

export const loadI18n = (resources) => {
  if (!resources) return;
  Object.keys(resources).forEach(lang => {
    i18next.addResourceBundle(lang, 'translation', resources[lang]);
    i18next.reloadResources();
  });
}

export const useI18n = () => {
  const [ready, setReady] = useState(false);
  const { t } = useTranslation('translation');
  useEffect(() => {
    // loadI18n(resources);
    setReady(true);
  }, [resources]);
  return ready ? t : key => key;
};

export default i18next;