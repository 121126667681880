import React from 'react';

const Button = ({testid ,onClick, href, style, className, pill, disabled, children, ...props }) => {
    return <a href={href}>
        <button
          data-testid={testid}
          disabled={disabled}
          onClick={onClick ? () => onClick() : null}
          className={`${className} btn ${pill ? 'btn-pill' : 'btn-primary'}`}
          style={style}
          {...props}
        >

            {children}
        </button>
    </a>
}


export default Button;