
import { useCallback, useMemo } from 'react';
import { useInstanceEvent, useStoreContext } from '../context/store';

const useAnswers = () => {
  const { dispatch, instance } = useStoreContext();
  const answers = useInstanceEvent('nav', (instance) => instance.nav.answers);

  const updateAnswer = useCallback((index, payload) => {
    const moduleInstance = instance.getModuleAnswerByIndex(index);
    
    if (moduleInstance) {
      moduleInstance.setScore(payload.score);
      moduleInstance.setStoreKey(payload.store_key ?? undefined);
      moduleInstance.setComment(payload.comment ?? '');
      moduleInstance.setMotives(payload.motives ?? []);
    }
  }, [dispatch, instance]);

  return useMemo(() => ({
    answers,
    updateAnswer,
  }), [answers, updateAnswer])
}

export default useAnswers;
